/* ChatPage.css */

/* Основная анимация для появления сообщений */
@keyframes slideUp {
    from {
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Анимация для системы */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Входящие сообщения */
@keyframes slideInFromLeft {
    from {
        transform: translateX(-20px);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

/* Исходящие сообщения */
@keyframes slideInFromRight {
    from {
        transform: translateX(20px);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

/* Стиль для анимации появления сообщений */
.message-enter {
    opacity: 0;
    transform: translateY(20px);
}

/* Активное состояние для входящего сообщения */
.message-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

/* Выходящие сообщения */
.message-exit {
    opacity: 1;
    transform: translateY(0);
}

/* Активное состояние для исчезающих сообщений */
.message-exit-active {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

/* Анимация для системных сообщений */
.message-system {
    animation: fadeIn 0.6s ease-out forwards;
}

/* Анимация для входящих сообщений */
.message-incoming {
    animation: slideInFromLeft 0.5s ease-out forwards;
}

/* Анимация для исходящих сообщений */
.message-outgoing {
    animation: slideInFromRight 0.5s ease-out forwards;
}

/* Анимация для статуса "typing" */
@keyframes typingDots {
    0% {
        content: '.';
    }
    33% {
        content: '..';
    }
    66% {
        content: '...';
    }
    100% {
        content: '';
    }
}

/* Стиль для индикатора "typing" */
.typing-indicator::after {
    display: inline-block;
    content: '';
    animation: typingDots 1.5s infinite;
    font-size: 12px;
    font-family: 'Poppins-Regular', 'sans-serif';
    color: #9347FF;
}

