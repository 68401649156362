/* Общий стиль страницы кошелька */
/* Общий стиль контейнера */
.wallet-container {
    background-color: #0E0E10;
    color: #FFFFFF;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    box-sizing: border-box;
    max-width: 100vw;
    overflow-x: hidden;
}

/* Таблица транзакций */
.transactions-card {
    background-color: #1F1E23;
    border-radius: 16px;
    padding: 16px;
    overflow: auto;
    max-height: 60vh;
    max-width: 100%;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
}

/* Таблица */
.transactions-table {
    width: 100%;
    min-width: 600px;
    border-collapse: collapse;
}

.transactions-table th,
.transactions-table td {
    padding: 12px 16px;
    text-align: left;
    font-size: 14px;
    color: #B0B0B0;
}

.transactions-table th {
    font-weight: bold;
    color: #FFFFFF;
    background-color: #2C2C2C;
}

.transactions-table tr:nth-child(even) {
    background-color: #252525;
}

.transactions-table tr:nth-child(odd) {
    background-color: #1F1E23;
}

/* Горизонтальный скроллинг при переполнении */
.transactions-card::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.transactions-card::-webkit-scrollbar-thumb {
    background: #555555;
    border-radius: 4px;
}

.transactions-card::-webkit-scrollbar-thumb:hover {
    background: #888888;
}

.transactions-card::-webkit-scrollbar-track {
    background: #2C2C2C;
}


h2, h3 {
    font-family: 'Poppins', sans-serif;
    color: #FFFFFF;
}
.balance-card {
    background-color: #1F1E23;
    border-radius: 16px;
    padding: 16px;
    margin-top: 65px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
}

.balance-card h2 {
    font-size: 24px;
    font-weight: bold;
}

.balance-title {
    color: white;
}

.balance-amount {
    color: #B794E4;
}


.balance-card p {
    font-size: 18px;
    color: #B0B0B0;
}

.error-text {
    color: #F44336;
    font-size: 14px;
}

.transactions-card table {
    width: 100%;
    border-collapse: collapse;
}

.transactions-card th, .transactions-card td {
    padding: 8px;
    text-align: left;
}

.transactions-card .positive {
    color: #4CAF50;
}

.transactions-card .negative {
    color: #F44336;
}

/* Кнопки */
.button-container {
    display: flex;
    gap: 16px;
}

.button {
    background-color: #9347FF;
    color: #FFFFFF;
    padding: 12px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    flex: 1;
    cursor: pointer;
    border: none;
    margin: 16px 1%;
}

.button:hover {
    background-color: #B794E4;
}
