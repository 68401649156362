/* ChatsLayout.css */
.chats-container {
    display: flex;
    height: 100vh;
    width: 100%;
}

.chats-container-dark {
    display: flex;
    height: 100vh;
    width: 100%;
    background: #1F1E23;
}

.chats-container-light {
    display: flex;
    height: 100vh;
    width: 100%;
    background: #C1BCD8;
}

/* Стили для списка чатов */
.chat-list {
    width: 30%;
    min-width: 300px;
    overflow-y: auto;
    transition: transform 0.3s ease-in-out;
    z-index: 10;
}

/* Стили для чата */
.chat-page {
    flex: 1;
    overflow-y: auto;
    position: relative;
    transition: transform 0.3s ease-in-out;
}

/* Адаптивность для мобильных устройств */
@media (max-width: 768px) {
    .chats-container {
        flex-direction: column;
    }

    .chat-list {
        width: 100%;
        min-width: 100%;
        border-right: none;
    }

    .chat-page {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 10;
        transform: translateX(100%);
    }

    /* Когда чат открыт */
    .chat-page.open {
        transform: translateX(0);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}
