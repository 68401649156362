
.profile-container {
    background-color: #0E0E10;
    color: #FFFFFF;
    padding: 20px;
    margin: 0;
    border-radius: 10px;
    box-sizing: border-box;
    max-width: 100vw;
    height: 100vh;
    overflow-x: hidden;
}

.profile-header {
    text-align: center;
}

.profile-avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 10px;
}

.profile-tag {
    color: #9347FF;
}

.profile-bio {
    margin-top: 20px;
}

.profile-balance {
    margin-top: 20px;
    font-size: 14px;
}

.action-button {
    background-color: #9347FF;
    color: #FFFFFF;
    border: none;
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
}
