.WhitePlaceholderInput::placeholder {
    color: #FFFFFF;
    opacity: 1;
}

.PurplePlaceholderInput::placeholder {
    color: #9347FF;
    opacity: 1;
}

input[type="range"]::-webkit-slider-thumb {
    appearance: none;
    width: 20px;
    height: 20px;
    background: white;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    transition: background 0.2s ease;
}

input[type="range"]::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: white;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    transition: background 0.2s ease;
}

input[type="range"]::-ms-thumb {
    width: 20px;
    height: 20px;
    background: white;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    transition: background 0.2s ease;
}
