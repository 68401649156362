@font-face {
    font-family: 'Poppins';
    src: url('../public/fonts/Poppins-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../public/fonts/Poppins-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../public/fonts/Poppins-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}